import React from "react";
import styles from "@aspen/theme/Home.module.less";
import type { IInjectProps } from "@aspen/model";
import { ADGM_BRAND, BRAND, AIPStrategyType } from "@aspen/model";
import { Button } from "antd";
import { YIELD_PATHS, YIELD_TYPES, i18nUtil, systemDetection } from "@aspen/libs";
import { withRouter } from "next/router";

interface IProps extends IInjectProps {}

interface IState {}

class DcaComponent extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
    this.handleClickOnStartNow = this.handleClickOnStartNow.bind(this);
    this.handleClickOnCreatePlan = this.handleClickOnCreatePlan.bind(this);
  }

  handleClickOnStartNow = () => {
    const { router } = this.props;
    const system = systemDetection();
    if (system === ADGM_BRAND) {
      router.push({
        pathname: YIELD_PATHS.YIELD_CREATE_DCA_PLAN,
        query: { strategyType: AIPStrategyType.Normal }
      });
    } else {
      router.push({ pathname: YIELD_PATHS.YIELD, query: { tabType: YIELD_TYPES.DCA } });
    }
  };

  handleClickOnCreatePlan(strategyType: number): void {
    this.props.router.push({
      pathname: YIELD_PATHS.YIELD_CREATE_DCA_PLAN,
      query: { strategyType }
    });
  }

  componentDidMount(): void {}

  render(): React.ReactNode {
    const intl = i18nUtil.t();
    const system = systemDetection();
    return (
      <div className={styles.dcaComponent}>
        <div style={{ padding: 24 }}>
          <p className={styles.title}>{intl["overview.savings.automatic.title"]}</p>
          <div className={styles.describeDiv}>
            <p className={styles.describe}>{intl["savings.autoInvestment.tooltips"]}</p>
            <Button
              type="primary"
              className={styles.startNowBtn}
              onClick={this.handleClickOnStartNow}>
              {intl["button.start.now"]}
            </Button>
          </div>
        </div>
        {system === BRAND ? (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div className={styles.planSpan}>
              <p className={styles.subTitle}>{intl["enums.autoInvestment.type.principal"]}</p>
              <p className={styles.subDescribe}>{intl["savings.automatic.describe.principal"]}</p>
              <Button
                className={styles.actionBtn}
                onClick={() => this.handleClickOnCreatePlan(AIPStrategyType.Preservation)}>
                {intl["button.create.plan"]}
              </Button>
            </div>
            <div className={styles.planSpan}>
              <p className={styles.subTitle}>{intl["enums.autoInvestment.type.double"]}</p>
              <p className={styles.subDescribe}>{intl["savings.automatic.describe.double"]}</p>
              <Button
                className={styles.actionBtn}
                onClick={() => this.handleClickOnCreatePlan(AIPStrategyType.Financing)}>
                {intl["button.create.plan"]}
              </Button>
            </div>
            <div className={styles.planSpan}>
              <p className={styles.subTitle}>{intl["enums.autoInvestment.type.DCA"]}</p>
              <p className={styles.subDescribe}>{intl["savings.automatic.describe.normal"]}</p>
              <Button
                className={styles.actionBtn}
                onClick={() => this.handleClickOnCreatePlan(AIPStrategyType.Normal)}>
                {intl["button.create.plan"]}
              </Button>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default withRouter(DcaComponent);
